@import 'mixins.scss';

.topbar {
  padding: 0 rem(30);
  height: 65px;
  border-bottom: 1px solid $border;
  color: $text;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  line-height: 10px !important;
  box-shadow: 16px 1px 15px rgba(0, 0, 0, 0.1);
  @media (max-width: $sm-max-width) {
    padding: 0 rem(16);
  }
}
.topRight {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

:global(.vb__layout__separatedHeader) {
  .topbar {
    background: $gray-1;
    padding: 0 rem(20);
    border-radius: 8px;
    border: none;
  }
}

// dark theme
[data-vb-theme='dark'] {
  .topbar {
    background: $dark-gray-6;
    color: $dark-gray-1;
    border-bottom: 1px solid $dark-gray-4;
  }

  :global(.vb__layout__separatedHeader) {
    .topbar {
      border: 1px solid $dark-gray-4;
    }
  }
}
.manageacct{
  margin-right: 5px;
}
.clinicinfo {
  // padding-left: 45px;
}
.toggleBtn {
  display: flex;
  align-items: center;
  justify-items: center;
}

@media (max-width: 768px) {
  .topbar {
    flex-direction: column; /* Stack elements vertically for mobile view */
    // padding: 10px 10px 0px 0px; /* Adjust padding for better spacing */
    padding: 0px;
    justify-content: start;
    // bottom: 100px;
    align-items: center; /* Adjust padding for better spacing */
  }

  .clinicinfo {
    margin-bottom: 10px;
     /* Add margin for separation */
    //  padding: 0px;
    text-align: center;
    justify-content: end;
    // padding: 18px 20px; /* Center text in mobile view */
  }

  .topRight {
    flex-direction: column; /* Stack elements vertically for mobile view */
    text-align: center; /* Center text in mobile view */
  }
}